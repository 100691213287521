import axios from 'axios';

const $axios = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'content-type': 'application/json',
    accept: 'application/json',
  },
});

const ENDPOINTS = {
  TOKEN: 'auth/token',
  PROVINCES: 'v1/province/spain',
  APPLICATION_B2C: 'v1/application/save',
  APPLICATION_B2B: 'v1/application/partner',
  GET_PRODUCTS_BY_LOAN: 'v1/loan/{id}/originator-products',
};

const getToken = async () => {
  try {
    const response = await $axios.get(ENDPOINTS.TOKEN);
    return response.data.token;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const getWithProhipotecasToken = async (endpoint) => {
  try {
    const token = await getToken();
    $axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } catch (error) {
    return false;
  }

  try {
    const response = await $axios.get(endpoint);
    return response.data;
  } catch (error) {
    return false;
  }
};

const submitSimulatorForm = async (params) => {
  try {
    const token = await getToken();
    $axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } catch (error) {
    return false;
  }

  let endpoint = ENDPOINTS.APPLICATION_B2C
  const storageParams = JSON.parse(sessionStorage.getItem('queryParams'))

  if (storageParams.organization) {
    params.organization = {
      id: storageParams.organization
    }
    endpoint = ENDPOINTS.APPLICATION_B2B
  }

  try {
    const response = await $axios.post(
      endpoint,
      params,
    );
    return response.data;
  } catch (error) {
    return false;
  }
};

const getProductsByLoan = async (loanId) => {
  try {
    const token = await getToken();
    $axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } catch (error) {
    return false;
  }

  try {
    const response = await $axios.post(ENDPOINTS.GET_PRODUCTS_BY_LOAN.replace('{id}', loanId), {});

    return response.data;
  } catch (error) {
    return false;
  }
};

export {
  $axios,
  ENDPOINTS,
  getToken,
  getWithProhipotecasToken,
  submitSimulatorForm,
  getProductsByLoan,
};
